import React from 'react'
import styled from 'styled-components'

const FeatureTitle = styled.div`
  color: black;
  font-weight: 600;
  line-height: 123.34%;
  font-size: 16px;
`

const FeatureText = styled.div`
  padding-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
`

const Feature = ({ children, title, text }) => (
  <div>
    {children}
    <FeatureTitle>{title}</FeatureTitle>
    <FeatureText>{text}</FeatureText>
  </div>
)

export default Feature