import React, { useState } from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled, { keyframes } from "styled-components";
import Feature from "../components/feature";
import Reason from "../components/reason";
import QuoteSlider from "../components/quoteSlider";
import RightArrow from "../components/rightArrow";
import Backed from "../components/backed";
import { GiMeditation } from "react-icons/gi";
import { FaChalkboardTeacher, FaUsers } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import "../styles/index.css";

const reasons = [
  {
    title: "Understanding leadership priorities and assessing staff needs",
    text: "We will work with your leadership and leverage survey data to understand how we can customize our programming to best align with your population’s needs.",
  },
  {
    title: "Maximizing engagement",
    text: "We work with your marketing and EAP teams to align messaging with other programs. We ensure that onboarding for staff members onto our app takes no longer than 2 minutes.",
  },
  {
    title: "Our analytics dashboard",
    text: "Assigned team members willl receive access to a dashboard that shows real-time well-being and engagement analytics to help you better understand how to support your staff.",
  },
];

const quotes = [
  {
    author: "Registered Nurse",
    quote:
      "I have been using Vitalize at the end of each of my shifts, and definitely want to continue making it a part of my daily routine. I have found the meditations super helpful.",
  },
  {
    author: "Registered Nurse",
    quote:
      "There is so much value in peer support, and the anonymity feature is very important to me. I especially love that there are topics like compassion fatigue, and co-worker relationships.",
  },
  {
    author: "Employee Health Nurse Manager",
    quote:
      "A one-of-a-kind tool that I have never seen before. It’s easily accessible, and can be used on-the-go. We need a program like yours.",
  },
];

const WaveAnimation = keyframes`
  0% { background-position: 100% }
  100% { background-position: -100%; }
`;

const Wave = styled.div`
  position: absolute;
  top: 650px;
  width: 100%;
  height: 275px;
  transform: skewY(-8deg);
  will-change: transform;
  z-index: 0;
  background: linear-gradient(
    82deg,
    #b2b0fa,
    #aab8f9,
    #a0c0f9,
    #aab8f9,
    #b2b0fa
  );
  background-size: 200% 200%;

  animation-name: ${WaveAnimation};
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @media (max-width: 600px) {
    top: 1000px;
  }
`;

const IndexPage = () => {
  const [reasonActive, setReasonActive] = useState(0);

  return (
    <Layout headerBackgroundColor={"#F7F9FC"}>
      <section className="Hero">
        <div className="HeroText">
          <h1>Redefining mental health for healthcare professionals</h1>
          <p style={{ maxWidth: 500 }}>
            We’re pioneering a care model centered around the challenges of
            working in healthcare.
          </p>
          <a
            href="https://calendly.com/veeraj-vitalize_meeting/vitalize-care-discovery"
            target="_blank"
            rel="noopener noreferrer"
            className="PurpleButton"
          >
            Request a demo
            <RightArrow />
          </a>
        </div>
        <img
          style={{ position: "absolute" }} // keep inline positioning due to gatsby build issues
          src="../images/dashboard.svg"
          className="HeroDashboard"
          alt="Vitalize Admin Dashboard"
        />
        <StaticImage
          style={{ position: "absolute" }} // keep inline positioning due to gatsby build issues
          src="../images/app.png"
          className="HeroPhone"
          alt="Vitalize App"
        />
        <Wave />
      </section>

      <section className="About" id="approach">
        <h4>What we do?</h4>
        <h2>Our Platform</h2>
        <p>
          Vitalize was co-designed with clinicians and healthcare adminstrators
          to best understand their mental health needs and challenges.
        </p>
        <div className="AboutGroup">
          <Feature
            title="On Demand Resources"
            text="Vitalize’s library of resources are focused on things that healthcare professionals face every day such as dealing with uncertainty in medicine, work-life balance, and coping with grief."
          >
            <GiMeditation
              size={40}
              color={"#6F83E0"}
              style={{ paddingBottom: 10 }}
            />
          </Feature>
          <Feature
            title="Clinician-Led Coaching"
            text="Our network of coaches are clinicians who have experienced the challenges of working in healthcare. We offer 1-on-1 and group sessions focused on building actionable skills."
          >
            <FaChalkboardTeacher
              size={40}
              color={"#6F83E0"}
              style={{ paddingBottom: 10 }}
            />
          </Feature>
          <Feature
            title="Stigma-Free Peer Support"
            text="We connect healthcare professionals with others in similar shoes, both live and via text. We create safe spaces by allowing them to change their username and even change their voice. "
          >
            <FaUsers
              size={40}
              color={"#6F83E0"}
              style={{ paddingBottom: 10 }}
            />
          </Feature>
          <Feature
            title="Analytics for Employers"
            text="We help organizational leadership unlock insights on mental health trends and drivers of burnout on a departmental level to help them better understand how to support their staff."
          >
            <GoGraph
              size={40}
              color={"#6F83E0"}
              style={{ paddingBottom: 10 }}
            />
          </Feature>
        </div>
      </section>

      <Backed />

      <section className="Coaching" id="coaching">
        <div className="CoachingText">
          <h2>Our approach to coaching</h2>
          <p>
            Traditional mental health platforms fail to understand what working
            in healthcare is like. We’re building a comprehensive,
            evidence-based solution that healthcare professionals will actually
            engage with.
          </p>
          <ul>
            <li>
              Topics that deeply resonate with healthcare professionals, such as
              secondary victim syndrome, difficult conversations with patients,
              and grief.
            </li>
            <li>
              Coaches that understand the realities of healthcare, and have
              worked with hundreds of healthcare professionals previously.
            </li>
            <li>
              Sessions offered almost daily and completely virtually, so minimal
              wait times the option to join at the comfort of their own home.
            </li>
          </ul>
        </div>
        <div className="CoachingImage">
          <div className="circles">
            <div className="inner-circle"></div>
            <div className="middle-circle"></div>
            <div className="outer-circle"></div>
          </div>
          <img src="../images/coaching.svg" alt="Vitalize Coaching" />
        </div>
      </section>

      <section className="Different" id="employers">
        <div className="DifferentContent">
          <div className="DifferentText">
            <h1>How we work with healthcare employers</h1>
            <div className="ReasonGroup">
              {reasons.map((reason, index) => (
                <Reason
                  {...reason}
                  index={index}
                  key={index}
                  onClick={setReasonActive}
                  isActive={reasonActive === index}
                />
              ))}
            </div>
          </div>
          <div className="DifferentImage">
            <StaticImage
              height={400}
              src="../images/different.png"
              alt="Healthcare Employers"
            />
          </div>
        </div>
      </section>

      <section className="Testimonial">
        <div className="QuoteSlider">
          {/* <h3>What healthcare professionals say about Vitalize Care</h3> */}
          <QuoteSlider quotes={quotes} />
        </div>
      </section>

      <section className="Demo">
        <div className="DemoContent">
          <div className="DemoText">
            <h2>
              Let’s improve the well-being of our healthcare workers, together.
            </h2>
            <a
              href="https://calendly.com/veeraj-vitalize_meeting/vitalize-care-discovery"
              target="_blank"
              rel="noopener noreferrer"
              className="PurpleButton"
            >
              Request a demo
              <RightArrow />
            </a>
          </div>
          <StaticImage
            src="../images/support.png"
            style={{ position: "absolute" }} // keep inline positioning due to gatsby build issues
            className="DemoImage"
            alt="Partner with Vitalize"
          />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
