import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import styled, { keyframes } from 'styled-components'

const BackedGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #F7F9FC;
  height: 250px;
  margin: auto;
`

const BannerMove = keyframes`
  ${'' /* 0% { transform: translate(0, 0); opacity: 0; }
  3% { transform: translate(-1%, 0); opacity: 1; }
  97% { transform: translate(-33%, 0); opacity: 1; }
  100% { transform: translate(-34%, 0); opacity: 0; } */}
  0% { transform: translate(0, 0);}
  100% { transform: translate(-34%, 0);}
`

const BackedContainer = styled.div`
  white-space: nowrap;
  animation-name: ${BannerMove};
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: all 2s ease;
  

  display: flex;
  gap: 90px;
  min-height: 90px;
  
  img {
    min-width: 70px;
    pointer-events: none;
    user-select: none;
  }
`

const Backed = () => {
  const renderImages = () => {
    return (
      <>
        <StaticImage
          src='../images/backed/burnout.png'
          objectFit={'contain'}
          alt='Clincian Burnout Foundation'
          style={{ padding: "50px", }}
        />
        <StaticImage
          src='../images/backed/washington.png'
          objectFit={'contain'}
          alt='Washington Business Journal'
          style={{ padding: "50px", }}
        />
        <StaticImage
          src='../images/backed/ycombinator.png'
          objectFit={'contain'}
          alt='Y Combinator'
          style={{ padding: "100px", }}
        />
        <StaticImage
          src='../images/backed/umd.png'
          objectFit={'contain'}
          alt='University of Maryland'
          style={{ padding: "40px", WebkitFilter: "grayscale(100%)" }}
        />
        <StaticImage
          src='../images/backed/midland.png'
          objectFit={'contain'}
          alt='Midland Health'
          style={{ padding: "25px", WebkitFilter: "grayscale(100%)" }}
        />
        <StaticImage
          src='../images/backed/startup-health.png'
          objectFit={'contain'}
          alt='Startup Health'
          style={{ padding: "90px", WebkitFilter: "grayscale(100%)" }}
        />
      </>
    )
  }

  return (
    <BackedGroup>
      <BackedContainer>
        {renderImages()}
        {renderImages()}
        {renderImages()}
      </BackedContainer>
    </BackedGroup>
  )
}

export default Backed