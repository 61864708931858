import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Slider = styled.div`
  margin: auto;
  display: "grid";
  padding: 80px 50px;
  align-items: center;
`

const QuoteContainer = styled.div`
  max-width: 1450px;
  min-height: 260px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    min-height: 300px;
  }
`

const Quote = styled.div`
  width: 100%;
  height: 100%;
  font-family: "PT Serif";
  font-size: 32px;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 26px;
  }
`

const Author = styled.div`
  padding: 30px 0 40px;
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
`

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const QuoteSlider = ({ quotes }) => {
  const [index, setIndex] = useState(0);
  const [hasClicked, setHasClicked] = useState(false);

  const dotStyle = {
    margin: "0 7px",
    cursor: "pointer",
    fontSize: "45px",
    width: "10px",
    height: "10px",
    borderRadius: 100,
    backgroundColor: "rgba(111, 131, 224, 0.3)",
  }

  const activeDotStyle = {
    margin: "0 7px",
    cursor: "pointer",
    fontSize: "45px",
    width: "10px",
    height: "10px",
    borderRadius: 100,
    backgroundColor: "#6F83E0",
  }

  const goNext = () => {
    const newIndex = index + 1;
    const d = quotes.length
    setIndex(((newIndex % d) + d) % d);
  }

  const goToSlide = (slideIndex) => {
    setIndex(slideIndex)
  }

  useEffect(() => {
    if (!hasClicked) {
      const interval = setInterval(goNext, 5000);
      return () => clearInterval(interval)
    }
  });

  return (
    <Slider>
      <QuoteContainer>
        <Quote>“{quotes[index].quote}”</Quote>
        <Author>{quotes[index].author}</Author>
      </QuoteContainer>
      <DotsContainer>
        {quotes.map((quote, quoteIndex) => (
          <div
            style={quoteIndex === index ? activeDotStyle : dotStyle}
            key={quoteIndex}
            onClick={() => {
              goToSlide(quoteIndex);
              setHasClicked(true);
            }}
            onKeyDown={() => {
              goToSlide(quoteIndex);
              setHasClicked(true);
            }}
            role="presentation"
          ></div>
        ))}
      </DotsContainer>
    </Slider>
  )
}

export default QuoteSlider