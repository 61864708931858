import React, { useCallback } from 'react'
import '../styles/reason.css'

const Reason = props => (
  <div
    onClick={useCallback(() => props.onClick(props.index), [props])}
    onKeyDown={useCallback(() => props.onClick(props.index), [props])}
    role="presentation"
    className={`Reason ${props.isActive ? 'active' : ''}` }
  >
    <div className="bar"></div>
    <div className="ReasonText">
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </div>
  </div>
)

export default Reason